import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import Axios, { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

// import { plainToClass } from 'class-transformer';

interface BrandFeedbackState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;

  brand: any;
  brand_feedback: any;

  brand_feedbacks: any;
  meta: any;
  form: any;

  cities: any;
  outlets: any;

  cleanFilterForm: any;
  filterForm: any;
  filter: any;
}

const initState: BrandFeedbackState = {
  loading: false,
  loaded: false,
  fatalError: false,

  brand: null,
  brand_feedback: null,

  brand_feedbacks: null,
  meta: null,
  form: null,

  cities: null,
  outlets: null,

  cleanFilterForm: {
    like: false,
    dislike: false,
    datetime_from: null,
    datetime_to: null,
    city_id: null,
    outlet_id: null,
    feedback_part: null,

  },

  filterForm: {
    like: false,
    dislike: false,
    datetime_from: null,
    datetime_to: null,
    city_id: null,
    outlet_id: null,
    feedback_part: null,
  },
  filter: {},
}

const initStateBackup: BrandFeedbackState = cloneDeep(initState)

const EMPTY_OBJECT = {
  answer: null,
}

const AVAILABLE_FIELDS = ['answer']


export const actions: ActionTree<BrandFeedbackState, RootState> = {
  loadData({ commit, state }, brand_id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      // vm.$http.get(`/admins/rest/v1/brands/${brand_id}/feedbacks`),
      vm.$http.get(`/admins/rest/v1/brands/${brand_id}`)
        .then((response: AxiosResponse) => {
          commit('SET_BRAND_TO_STATE', response.data.brand)
          commit('GENERATE_FORM_DATA')
          commit('SET_BRAND_MARK_TO_STATE', response.data.brand_feedback)
          // commit('SET_FEEDBACK_LIST', response.data.brand_feedbacks);
          commit('LOADED_SUCCESS')
        })
        .catch((error: AxiosError) => {
          console.log('error', error)
          commit('LOADED_ERROR')
        })

      vm.$http.get(`/admins/rest/v1/cities`)
        .then((response: AxiosResponse) => {
          commit('SET_CITIES_LIST', response.data)
          commit('LOADED_SUCCESS')
        })
        .catch((error: AxiosError) => {
          console.log('error', error)
          commit('LOADED_ERROR')
        })

      const filter = {
        brand_id,
      }

      vm.$http.get(`/admins/rest/v1/outlets`, {
        params: {
          ...filter,
        },
      })
        .then((response: AxiosResponse) => {
          commit('SET_OUTLETS_LIST', response.data)
          commit('LOADED_SUCCESS')
        })
        .catch((error: AxiosError) => {
          console.log('error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  loadFeedback({ commit, state }, brand_id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const filter = {
        like: state.filter.like,
        dislike: state.filter.dislike,
        datetime_from: state.filter.datetime_from,
        datetime_to: state.filter.datetime_to,
        city_id: state.filter.city_id,
        outlet_id: state.filter.outlet_id,
        feedback_part: state.filter.feedback_part,
      }

      vm.$http.get(`/admins/rest/v1/brands/${brand_id}/feedbacks`, {
        params: {
          ...filter,
        },
      })
        .then((response: AxiosResponse) => {
          commit('SET_FEEDBACK_LIST', response.data)
          commit('SET_META_FEEDBACK_LIST', response.data.meta)
          commit('LOADED_SUCCESS')
        })
        .catch((error: AxiosError) => {
          console.log('error', error)
          commit('LOADED_ERROR')
        })
    })
  },
  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  changeFilterFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_FILTER_FORM_VALUE', { field, newValue })
  },
  resetData({ state, commit }) {
    state.brand_feedbacks = null

    state.meta = null
    state.form = null
  },

  applyFilter({ commit, dispatch }, brand_id) {
    commit('APPLY_FILTER')
    dispatch('resetData')
    dispatch('loadFeedback', brand_id)
  },

  saveObject({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const cleanForm: any = {}
      for (const key of AVAILABLE_FIELDS) {
        if (state.form[key]) {
          cleanForm[key] = state.form[key]
        }
      }
      // console.log(cleanForm);


      commit('START_LOADING')

      let request = null

      request = vm.$http.patch(`/admins/rest/v1/brands/${id}/feedbacks`, {
        feedback: cleanForm,
      })
      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
          }
        })
    })
  },
  // clearFilter({ state, dispatch }) {
  //     state.filter = {};
  //     state.filterForm = cloneDeep(state.cleanFilterForm);
  //     dispatch('resetData');
  //     dispatch('loadData');
  //     dispatch('loadFeedback');
  // },
}

export const mutations: MutationTree<BrandFeedbackState> = {
  RESET_STATE: (state: BrandFeedbackState) => {
    // tslint:disable-next-line:forin
    for (const key in initStateBackup) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  CHANGE_VALUE(state: BrandFeedbackState, { field, newValue }) {
    state.form[field] = newValue
  },
  GENERATE_FORM_DATA(state) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
    }
  },
  CHANGE_FILTER_FORM_VALUE(state: BrandFeedbackState, { field, newValue }) {
    switch (field) {
      case 'datetime_from':
      case 'datetime_to':
        state.filterForm[field] = moment(newValue).format('YYYY-MM-DDTHH:mm:ss')
        break
      default:
        state.filterForm[field] = newValue
    }
  },
  APPLY_FILTER(state: BrandFeedbackState) {
    state.filter = state.filterForm
  },
  SET_BRAND_TO_STATE: (state, brand) => {
    state.brand = brand
  },
  SET_BRAND_MARK_TO_STATE: (state, brand_feedback) => {
    state.brand_feedback = brand_feedback
  },
  SET_FEEDBACK_LIST(state, data) {
    state.brand_feedbacks = data.brand_feedbacks
  },
  SET_CITIES_LIST(state, data) {
    state.cities = data.cities
  },
  SET_OUTLETS_LIST(state, data) {
    state.outlets = data.outlets
  },
  SET_META_FEEDBACK_LIST(state, meta) {
    state.meta = meta
  },
}

export const $getters: GetterTree<BrandFeedbackState, RootState> = {
  brand: (state: any) => {
    return state.brand
  },
  brand_feedback: (state: any) => {
    return state.brand_feedback
  },
}

export const STORE_KEY = '$_brand_feedbacks'

export const store = {
  namespaced: true,
  state: initState,
  actions,
  getters: $getters,
  mutations,
}
