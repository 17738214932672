




































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
// @ts-ignore
import StarRating from 'vue-star-rating'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'brand_feedbacks', 'meta', 'form', 'filter',
      'cities', 'outlets', 'brand_feedback',
    ]),
    ...mapGetters(STORE_KEY, [
      'brand', 'brand_feedback',
    ]),
  },
  validations() {
    const rules: any = {
      answer: { required },
    }
    const filt: any = {
      like: {},
      dislike: {},
    }

    return {
      form: rules,
      filter: filt,
    }
  },
  components: {
    TitleBar, StarRating,
  },
})

export default class BrandFeedback extends Vue {
  public item: any = null
  public num: any = null
  public showModal: any = false

  public selectFeedback(item: any, index: any) {
    this.item = item
    this.num = index
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public changeFilterValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFilterFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.filter[field].$touch()
  }

  public clearFilterAndClose() {
    this.$store.dispatch(`${STORE_KEY}/clearFilter`)
  }

  public submitObject() {
    this.$v.form.$touch()


    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, this.item.id)
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)

          // this.$router.push({ name: 'brand-list'});
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: `При сохраненит возникли проблемы. Попробуйте позже.`,
          })
        })
    }

  }


  public submitFilter() {
    this.$store.dispatch(`${STORE_KEY}/applyFilter`, this.$route.params.id)
    this.showModal = false
  }

  // public submitObject() {
  //   this.$store.dispatch(`${STORE_KEY}/saveObject`, {id: this.item.id, index: this.num});
  // }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.params.id)
    this.$store.dispatch(`${STORE_KEY}/loadFeedback`, this.$route.params.id)
  }


}
